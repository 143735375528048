<template>
  <v-row
    v-if="!isLoading"
    class="match-height"
  >
    <v-col cols="12">
      <Loading
        v-if="loadGalery"
        :full-page="true"
        :color="$vuetify.theme.themes.light.primary"
      />

      <app-card-code :title="`${$t('menu.products')} / ${$t('menu.hotels')}`">
        <v-card-text>
          <v-row>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                color="error"
                class="mb-4 me-3"
                @click="
                  ;`${
                    comeFromContrate
                      ? $router.push({ name: 'hotels-contrate-update' })
                      : $router.push({ name: 'hotels-list' })
                  }`
                "
              >
                <v-icon>{{ icons.mdiArrowLeft }}</v-icon>
                <span>{{ $t('btn.back') }}</span>
              </v-btn>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-btn
                v-if="!$store.state.app.onlyShow"
                color="primary"
                class="mb-4 me-3"
                :loading="loading"
                :disabled="loadGalery"
                @click="save()"
              >
                <span>{{ btnTitle }}</span>
                <v-icon>{{ icons.mdiUpdate }}</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <!--MODAL ROOMS CHANGE-->
          <v-dialog
            v-model="isDialogRooms"
            scrollable
            max-width="350px"
          >
            <v-card max-height="400px">
              <v-card-title>{{ model.name }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p class="mt-5">
                  {{ $t('msg.msgChangeRooms', { item: model.name }) }}
                </p>
              </v-card-text>
              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="primary"
                  outlined
                  @click="isDialogRooms = !isDialogRooms"
                >
                  {{ $t('btn.close') }}
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="error"
                  outlined
                  :loading="loading"
                  @click="confirmRoomsHotetec()"
                >
                  {{ $t('btn.send') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <!--MODAL DE LOS NOMBRES ANTIGUOS-->
          <v-dialog
            v-model="isDialogVisible"
            scrollable
            max-width="450px"
          >
            <v-card
              max-height="600px"
              height="550px"
            >
              <v-card-title>{{ $t('lbl.nameOlds') }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text v-if="!$store.state.app.onlyShow">
                <v-row class="mt-5">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-text-field
                      v-model="newNameOld.name"
                      :label="$t('lbl.name')"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </v-col>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <vc-date-picker
                      v-model="newNameOld.date"
                      outlined
                      dense
                      hide-details
                      mode="date"
                      :model-config="modelConfig"
                      class="d-flex justify-center;z-index: 1 !important"
                    >
                      <template v-slot="{ inputValue, inputEvents }">
                        <v-text-field
                          :label="$t('lbl.date')"
                          outlined
                          dense
                          :value="inputValue"
                          v-on="inputEvents"
                        >
                        </v-text-field>
                      </template>
                    </vc-date-picker>
                  </v-col>

                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-btn
                      color="primary"
                      outlined
                      @click="isDialogVisible = false"
                    >
                      {{ $t('btn.close') }}
                    </v-btn>
                  </v-col>
                  <v-col
                    cols="12"
                    md="5"
                  ></v-col>
                  <v-col
                    cols="12"
                    md="3"
                  >
                    <v-btn
                      color="primary"
                      outlined
                      :loading="loading"
                      @click="saveNameOld"
                    >
                      {{ $t('btn.send') }}
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>

              <v-divider></v-divider>
              <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-uppercase">
                          {{ $t('lbl.name') }}
                        </th>
                        <th class="text-uppercase">
                          {{ $t('lbl.date') }}
                        </th>
                        <th
                          v-if="!$store.state.app.onlyShow"
                          class="text-uppercase"
                          width="15%"
                        >
                          {{ $t('lbl.actions') }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(iten, index) in nameOlds"
                        :key="index"
                      >
                        <td>{{ iten.name }}</td>
                        <td>{{ iten.date | moment('MMMM, YYYY') }}</td>
                        <td v-if="!$store.state.app.onlyShow">
                          <v-tooltip
                            top
                            color="error"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn
                                icon
                                v-bind="attrs"
                                v-on="on"
                                @click="deleteNameOld(index)"
                              >
                                <v-icon>{{ icons.mdiDeleteOutline }}</v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t('btn.delete') }}</span>
                          </v-tooltip>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-card-text>
            </v-card>
          </v-dialog>
          <!--MODAL DE LAS EDADES-->
          <v-dialog
            v-model="isDialogVisibleEdad"
            scrollable
            max-width="450px"
          >
            <v-card
              max-height="600px"
              height="300px"
            >
              <v-card-title>{{ $t('menu.edadMin') }}</v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <v-row>
                  <v-col
                    cols="12"
                    md="12"
                  >
                    <v-radio-group
                      v-model="model.edad_min_id"
                      row
                    >
                      <v-row>
                        <v-col
                          v-for="(eda, iEdad) in edades"
                          :key="iEdad"
                          cols="12"
                          md="3"
                        >
                          <v-radio
                            :label="eda.name"
                            :value="eda.id"
                            @change="changeModalEdad(eda.id)"
                          ></v-radio>
                        </v-col>
                      </v-row>
                    </v-radio-group>
                  </v-col>
                </v-row>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-btn
                  color="primary"
                  outlined
                  @click="changeEdad"
                >
                  {{ $t('btn.close') }}
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <v-form class="multi-col-validation">
            <v-row class="pb-5">
              <v-col
                cols="12"
                md="4"
              >
                <v-text-field
                  v-model="model.name"
                  :label="$t('lbl.name')"
                  outlined
                  dense
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                >
                  <v-tooltip
                    slot="append-outer"
                    top
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="showOldsName"
                      >
                        <v-icon color="primary">
                          {{ icons.mdiPlaylistEdit }}
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t('lbl.nameOlds') }}</span>
                  </v-tooltip>
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.operador_id"
                  :items="itemsOperadores"
                  :search-input.sync="searchOperador"
                  hide-details
                  hide-selected
                  :label="$t('menu.operador')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  @change="setOperadores"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.operador') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.marca_asociada_id"
                  :items="itemsMarcas"
                  :search-input.sync="searchMarcas"
                  hide-details
                  hide-selected
                  :label="$t('menu.marcaAsociada')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  @change="changeCumpleTags()"
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.marcaAsociada') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-select
                  v-model="model.categoria_id"
                  :items="categories"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.category')"
                  outlined
                  dense
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @change="changeCumpleTags()"
                >
                  <template v-slot:selection="{ item }">
                    <span v-if="item.name !== null">
                      {{ item.name }}
                      <span v-if="item.plus">PLUS</span>
                    </span>
                    <span v-if="item.name === null">
                      <v-row>
                        <v-col
                          cols="12"
                          md="8"
                        >
                          <v-rating
                            v-model="item.cant_estrellas"
                            color="primary"
                            size="30"
                            readonly
                          ></v-rating>
                        </v-col>
                        <v-col
                          cols="12"
                          md="4"
                          class="mt-2"
                        >
                          <span v-if="item.plus">PLUS</span>
                        </v-col>
                      </v-row>
                    </span>
                  </template>

                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title v-if="item.name !== null">
                        {{ item.name }}
                        <span v-if="item.plus">PLUS</span>
                      </v-list-item-title>
                      <v-list-item-title v-if="item.name === null">
                        <v-row>
                          <v-col
                            cols="12"
                            md="8"
                          >
                            <v-rating
                              v-model="item.cant_estrellas"
                              color="primary"
                              readonly
                              size="30"
                            ></v-rating>
                          </v-col>
                          <v-col
                            cols="12"
                            md="2"
                          >
                            <p
                              v-if="item.plus"
                              class="pt-3"
                            >
                              PLUS
                            </p>
                          </v-col>
                        </v-row>
                      </v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-select>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-autocomplete
                  v-model="model.tipo_destino_id"
                  :items="itemsTipoDestino"
                  :search-input.sync="searchTipoDestino"
                  hide-details
                  hide-selected
                  :label="$t('menu.typeDestination')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  @change="
                    changeCumpleTags()
                    changeTipoDestino()
                  "
                >
                  <template v-slot:no-data>
                    <v-list-item>
                      <v-list-item-title>
                        {{ $t('menu.typeDestination') }}
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <template v-slot:selection="{ item }">
                    <span v-text="item.name"></span>
                  </template>
                  <template v-slot:item="{ item }">
                    <v-list-item-content>
                      <v-list-item-title>{{ item.name }}</v-list-item-title>
                    </v-list-item-content>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <!--<v-select
                  v-model="model.edad_min_id"
                  :items="edades"
                  item-text="name"
                  item-value="id"
                  :label="$t('menu.edadMin')"
                  outlined
                  dense
                  hide-details
                >
                </v-select>-->
                <!--<v-switch
                  v-model="model.only_adult"
                  :label="
                    `${$t('lbl.only_adult')} ${
                      model.only_adult && model.edad_min_id !== null && edad_min.edad !== undefined
                        ? `${edad_min.edad !== null ? `(+${edad_min.edad})` : ''}`
                        : ''
                    }`
                  "
                  color="primary"
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @change="onlyAdult"
                ></v-switch> -->
              </v-col>

              <v-col
                cols="12"
                md="2"
              >
                <!--<v-switch
                  v-model="model.all_include"
                  :label="$t('lbl.allInclude')"
                  color="primary"
                  hide-details
                  :disabled="$store.state.app.onlyShow"
                  @change="allInclude"
                ></v-switch>-->
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="modelIntereses"
                  :items="disabledInteres"
                  :label="$t('menu.intereses')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  multiple
                  :search-input.sync="searchIntereses"
                  @click="changeCumpleTags()"
                  @change="clickNativeInteres($event)"
                >
                </v-autocomplete>
                <!--<strong>{{ $t('menu.intereses') }}</strong>
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-4"
                      color="primary"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="showIntereses = !showIntereses"
                    >
                      <v-icon>
                        {{ showIntereses ? icons.mdiMenuDown : icons.mdiMenuRight }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ showIntereses ? $t('lbl.lessSee') : $t('lbl.moreSee') }}</span>
                </v-tooltip>
                <v-row>
                  <fragment
                    v-for="(interes, i) in intereses"
                    :key="i"
                  >
                    <v-col
                      v-if="i < 12"
                      cols="12"
                      md="3"
                    >
                      <v-checkbox
                        v-model="modelIntereses"
                        :label="interes.name"
                        :value="interes.id"
                        hide-details
                        :disabled="
                          $store.state.app.onlyShow ||
                            (model.only_adult === true && interes.slug === 'apto-para-familias')
                        "
                        @click="changeCumpleTags()"
                        @click.native="
                          interes.slug === 'todo-incluido'
                            ? checkAllInclude($event)
                            : interes.slug === 'solo-adultos'
                              ? checkOnlyAdultInteres($event)
                              : ''
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      v-if="i >= 12 && showIntereses"
                      cols="12"
                      md="3"
                    >
                      <v-checkbox
                        v-model="modelIntereses"
                        :label="interes.name"
                        :value="interes.id"
                        hide-details
                        :disabled="
                          $store.state.app.onlyShow ||
                            (model.only_adult === true && interes.slug === 'apto-para-familias')
                        "
                        @click="changeCumpleTags()"
                        @click.native="
                          interes.slug === 'todo-incluido'
                            ? checkAllInclude($event)
                            : interes.slug === 'solo-adultos'
                              ? checkOnlyAdultInteres($event)
                              : ''
                        "
                      ></v-checkbox>
                    </v-col>
                  </fragment>
                </v-row>-->
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="modelServicios"
                  :items="servicios"
                  :label="$t('menu.servicios')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  multiple
                  :search-input.sync="searchServicios"
                  @click="changeCumpleTags()"
                  @change="clickNativeServicios($event)"
                >
                </v-autocomplete>
                <!--<strong>{{ $t('menu.servicios') }}</strong>
                <v-tooltip
                  top
                  color="primary"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="ml-4"
                      color="primary"
                      fab
                      x-small
                      v-bind="attrs"
                      v-on="on"
                      @click="showServices = !showServices"
                    >
                      <v-icon>
                        {{ showServices ? icons.mdiMenuDown : icons.mdiMenuRight }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ showServices ? $t('lbl.lessSee') : $t('lbl.moreSee') }}</span>
                </v-tooltip>
                <v-row>
                  <fragment
                    v-for="(servicio, i) in servicios"
                    :key="i"
                  >
                    <v-col
                      v-if="i < 12"
                      cols="12"
                      md="3"
                    >
                      <v-checkbox
                        v-model="modelServicios"
                        :label="servicio.name"
                        :value="servicio.id"
                        hide-details
                        :prepend-icon="servicio.icon != null ? servicio.icon : null"
                        :disabled="$store.state.app.onlyShow"
                        @click="changeCumpleTags()"
                        @click.native="
                          servicio.slug === 'todo-incluido'
                            ? checkAllIncludeServ($event)
                            : servicio.slug === 'solo-adultos'
                              ? checkOnlyAdultServicios($event)
                              : ''
                        "
                      ></v-checkbox>
                    </v-col>
                    <v-col
                      v-if="i >= 12 && showServices"
                      cols="12"
                      md="3"
                    >
                      <v-checkbox
                        v-model="modelServicios"
                        :label="servicio.name"
                        :value="servicio.id"
                        hide-details
                        :prepend-icon="servicio.icon != null ? servicio.icon : null"
                        :disabled="$store.state.app.onlyShow"
                        @click="changeCumpleTags()"
                        @click.native="
                          servicio.slug === 'todo-incluido'
                            ? checkAllIncludeServ($event)
                            : servicio.slug === 'solo-adultos'
                              ? checkOnlyAdultServicios($event)
                              : ''
                        "
                      ></v-checkbox>
                    </v-col>
                  </fragment>
                </v-row>-->
              </v-col>
              <v-col
                cols="12"
                md="12"
              >
                <v-autocomplete
                  v-model="model.rooms_id"
                  :items="allRooms"
                  :label="$t('lbl.room')"
                  outlined
                  dense
                  item-text="name"
                  item-value="id"
                  :disabled="$store.state.app.onlyShow"
                  multiple
                  :search-input.sync="searchRooms"
                  @change="itemChange"
                >
                </v-autocomplete>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-switch
                  v-model="model.publicado"
                  :label="$t('lbl.publico')"
                  color="primary"
                  hide-details
                  :disabled="$store.state.app.onlyShow || !cumpleTags"
                  @change="changePublic"
                ></v-switch>
              </v-col>
              <v-col
                cols="12"
                md="4"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-switch
                      v-model="model.sugerido"
                      :label="$t('lbl.sugerido')"
                      color="primary"
                      hide-details
                      :disabled="$store.state.app.onlyShow"
                      @change="changeSugerido"
                    ></v-switch>
                  </v-col>
                  <v-col
                    v-if="model.sugerido"
                    cols="12"
                    md="4"
                  >
                    <v-select
                      v-model="model.number_sugerido"
                      :items="numeros"
                      :label="$t('lbl.orderSugerido')"
                      outlined
                      dense
                      hide-details
                      class="mt-2"
                      @change="changeCumpleTags()"
                    ></v-select>
                  </v-col>
                  <v-col
                    v-if="model.sugerido"
                    cols="12"
                    md="2"
                    class="pt-2"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          v-on="on"
                        >
                          <v-icon color="primary">
                            mdi-information
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('lbl.infoSugerido') }}</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <vc-date-picker
                  v-model="model.checkIn"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  is24hr
                  @input="changeCumpleTags()"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      label="Check-In"
                      outlined
                      dense
                      :value="inputValue"
                      :disabled="$store.state.app.onlyShow"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <vc-date-picker
                  v-model="model.checkOut"
                  outlined
                  dense
                  mode="time"
                  :model-config="modelTimeConfig"
                  is24hr
                  @input="changeCumpleTags()"
                >
                  <template v-slot="{ inputValue, inputEvents }">
                    <v-text-field
                      label="Check-Out"
                      outlined
                      dense
                      :value="inputValue"
                      :disabled="$store.state.app.onlyShow"
                      v-on="inputEvents"
                    >
                    </v-text-field>
                  </template>
                </vc-date-picker>
              </v-col>
            </v-row>

            <v-row>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.cant_rooms"
                  :label="$t('lbl.cantRoom')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.cant_restaurant"
                  :label="$t('lbl.cantRest')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.cant_bars"
                  :label="$t('lbl.cantBars')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.cant_pools"
                  :label="$t('lbl.cantPiscinas')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                >
                </v-text-field>
              </v-col>
              <v-col
                cols="12"
                md="2"
              >
                <v-text-field
                  v-model="model.cant_coffee"
                  :label="$t('lbl.cantSalon')"
                  outlined
                  dense
                  hide-details
                  type="number"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                >
                </v-text-field>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <tiptap-vuetify
                  v-model="model.descripcion"
                  :extensions="extensions"
                  :placeholder="`${$t('lbl.description')}...`"
                  :disabled="$store.state.app.onlyShow"
                  @input="changeCumpleTags()"
                />
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <v-row>
                  <v-col
                    cols="12"
                    md="4"
                  >
                    <v-row class="pb-5">
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="addressHotel.country"
                          :label="$t('lbl.country')"
                          outlined
                          dense
                          hide-details
                          :disabled="$store.state.app.onlyShow"
                          @input="changeCumpleTags()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="addressHotel.state"
                          :label="$t('lbl.provinceEstado')"
                          outlined
                          dense
                          hide-details
                          :disabled="$store.state.app.onlyShow"
                          @input="changeCumpleTags()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-text-field
                          v-model="addressHotel.city"
                          :label="$t('lbl.locality')"
                          outlined
                          dense
                          hide-details
                          :disabled="$store.state.app.onlyShow"
                          @input="changeCumpleTags()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <v-textarea
                          v-model="addressHotel.direccion"
                          :label="$t('lbl.address')"
                          outlined
                          no-resize
                          rows="3"
                          row-height="15"
                          :disabled="$store.state.app.onlyShow"
                          @input="changeCumpleTags()"
                        ></v-textarea>
                      </v-col>
                    </v-row>
                  </v-col>
                  <!--GEOTAG-->
                  <v-col
                    cols="12"
                    md="8"
                  >
                    <v-row class="pb-5">
                      <v-col
                        cols="12"
                        md="12"
                      >
                        <GeoTag
                          v-if="!editGeoTag"
                          :zoom="zoom"
                        />
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="$store.state.app.geotag.longitud"
                          disabled
                          :label="$t('lbl.longitud')"
                          outlined
                          dense
                          hide-details
                          @input="changeCumpleTags()"
                        ></v-text-field>
                      </v-col>
                      <v-col
                        cols="12"
                        md="6"
                      >
                        <v-text-field
                          v-model="$store.state.app.geotag.latitud"
                          disabled
                          :label="$t('lbl.latitud')"
                          outlined
                          dense
                          hide-details
                          @input="changeCumpleTags()"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-col>

              <v-col
                cols="12"
                md="12"
              >
                <strong>{{ $t('lbl.contacts') }}</strong>
                <v-tooltip
                  v-if="!$store.state.app.onlyShow"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      class="mx-2"
                      fab
                      dark
                      x-small
                      color="primary"
                      @click="adicionarContactHotel()"
                    >
                      <v-icon
                        v-bind="attrs"
                        v-on="on"
                      >
                        {{ icons.mdiPlus }}
                      </v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('btn.insert') }}</span>
                </v-tooltip>
              </v-col>
              <v-col
                cols="12"
                sm="12"
                md="12"
                class="pb-5"
              >
                <v-row
                  no-gutters
                  class="pt-5 pb-5"
                >
                  <Contacts
                    v-for="(contact, i) in $store.state.app.contactsHotel"
                    :key="i"
                    :contact="contact"
                    :pos="i"
                  />
                </v-row>
              </v-col>

              <fragment v-if="model.id">
                <v-col
                  cols="12"
                  md="12"
                >
                  <strong>{{ $t('lbl.galery') }}</strong>
                </v-col>
                <v-col
                  v-if="!$store.state.app.onlyShow"
                  cols="12"
                  md="6"
                >
                  <v-file-input
                    v-model="galery"
                    accept=".png, .jpg, .jpeg, .webp"
                    exc
                    show-size
                    outlined
                    dense
                    :label="$t('lbl.searchImages')"
                    hide-details
                    multiple
                    :disabled="$store.state.app.onlyShow || loadGalery"
                    @change="setGalery"
                  >
                    <v-tooltip
                      slot="append-outer"
                      top
                      color="error"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          v-bind="attrs"
                          class="mt-0 pb-3"
                          v-on="on"
                        >
                          <v-icon color="error">
                            {{ icons.mdiInformation }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('lbl.cantImgUpload', { item: 20 }) }}</span>
                    </v-tooltip>
                  </v-file-input>
                </v-col>

                <fragment v-if="nameB2B !== 'ttttt' && !$store.state.app.onlyShow">
                  <v-col
                    cols="12"
                    md="6"
                  >
                    <v-file-input
                      v-model="videos"
                      accept="video/*"
                      exc
                      show-size
                      outlined
                      dense
                      :label="$t('lbl.searchVideos')"
                      hide-details
                      multiple
                      :disabled="$store.state.app.onlyShow || loadGalery"
                      @change="setVideos"
                    >
                      <v-tooltip
                        slot="append-outer"
                        top
                        color="error"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            icon
                            v-bind="attrs"
                            class="mt-0 pb-3"
                            v-on="on"
                          >
                            <v-icon color="error">
                              {{ icons.mdiInformation }}
                            </v-icon>
                          </v-btn>
                        </template>
                        <span>{{ $t('lbl.sizeVideoUpload', { item: 5 }) }}</span>
                      </v-tooltip>
                    </v-file-input>
                  </v-col>

                  <v-col
                    cols="12"
                    md="12"
                  >
                    <strong>{{ $t('lbl.videosUrl') }}</strong>
                    <v-tooltip
                      v-if="!$store.state.app.onlyShow"
                      top
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="mx-2"
                          fab
                          dark
                          x-small
                          color="primary"
                          @click="adicionarUrlVideo()"
                        >
                          <v-icon
                            v-bind="attrs"
                            v-on="on"
                          >
                            {{ icons.mdiPlus }}
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>{{ $t('btn.insert') }}</span>
                    </v-tooltip>
                  </v-col>
                </fragment>
                <VideosURL
                  v-for="(url, i) in videos_url"
                  :key="i"
                  :url="url"
                  :pos="i"
                />

                <Videos
                  v-for="(video, indV) in videosHotels"
                  :key="Math.random() * (indV + 1)"
                  :pos="indV"
                  :video="video"
                  @reset-video-item="resetVideoItem()"
                />

                <Galery
                  v-for="(gale, i) in galeriaHotels"
                  :key="i"
                  :pos="i"
                  :image="gale"
                  @change-cumple-tags="changeCumpleTags()"
                  @reset-galery-item="resetGaleryItem()"
                />
              </fragment>
              <fragment v-else>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-btn
                    v-if="!$store.state.app.onlyShow"
                    color="primary"
                    class="mb-4 me-3"
                    :loading="loading"
                    @click="addImg()"
                  >
                    <span>{{ $t('lbl.addImages') }}</span>
                    <v-icon>{{ icons.mdiImagePlus }}</v-icon>
                  </v-btn>
                </v-col>
                <v-col
                  v-if="loadGalery"
                  cols="12"
                  md="12"
                >
                  <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
                </v-col>
              </fragment>
            </v-row>
          </v-form>
        </v-card-text>
      </app-card-code>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations, mapState } from 'vuex'
// eslint-disable-next-line import/no-unresolved
import AppCardCode from '@/@core/components/app-card-code/AppCardCode.vue'
// eslint-disable-next-line import/no-unresolved
import Loading from '@/views/utils/loading/bars.vue'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiSquareEditOutline,
  mdiPlus,
  mdiArrowLeft,
  mdiUpdate,
  mdiEyeOutline,
  mdiEyeOffOutline,
  mdiTrashCan,
  mdiAirplaneTakeoff,
  mdiCity,
  mdiPlaylistEdit,
  mdiImagePlus,
  mdiInformation,
  mdiMenuDown,
  mdiMenuRight,
} from '@mdi/js'
import {
  TiptapVuetify,
  Bold,
  Italic,
  HardBreak /* Paragraph, Strike, Heading, Underline, Code, BulletList, OrderedList, ListItem, Link, Blockquote, HorizontalRule, History, */,
} from 'tiptap-vuetify'
// eslint-disable-next-line import/no-unresolved
import VideosURL from '@/views/utils/videos/Videos.vue'
import Galery from '../utils/Galery.vue'
import GeoTag from '../utils/GeoTag.vue'
import Contacts from '../utils/Contacts.vue'
import Videos from '../utils/Videos.vue'

export default {
  components: {
    AppCardCode,
    Loading,
    TiptapVuetify,
    Galery,
    GeoTag,
    Contacts,
    Videos,
    VideosURL,
  },
  data() {
    return {
      comeFromContrate: sessionStorage.getItem('contrato-hotels-show'),
      isLoading: true,
      rutaPublic: process.env.VUE_APP_API_URL_PLUBLIC,
      nameB2B: process.env.VUE_APP_PROJ_NAME,
      zoom: 14,
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiSquareEditOutline,
        mdiPlus,
        mdiArrowLeft,
        mdiUpdate,
        mdiEyeOutline,
        mdiEyeOffOutline,
        mdiTrashCan,
        mdiAirplaneTakeoff,
        mdiCity,
        mdiPlaylistEdit,
        mdiImagePlus,
        mdiInformation,
        mdiMenuDown,
        mdiMenuRight,
      },
      extensions: [
        /* History,
        Blockquote,
        Link,
        Underline,
        Strike,
        ListItem,
        BulletList,
        OrderedList,
        [Heading, {
          options: {
            levels: [1, 2, 3],
          },
        }],
        Code,
        Paragraph,
        HorizontalRule, */
        Italic,
        Bold,
        HardBreak,
      ],
      modelTimeConfig: {
        type: 'string',
        mask: 'HH:mm',
      },
      transmisiones: [],
      marcas: [],
      combustibles: [],
      caracteristicas: [],
      modelCaracteristicas: [],
      model: {
        checkIn: '16:00',
        checkOut: '12:00',
        cant_bars: 0,
        cant_restaurant: 0,
        cant_rooms: 0,
        cant_pools: 0,
        cant_coffee: 0,
      },
      edad_min: {},
      loading: false,

      items: [],
      typeAuto: [],
      itemsTypeAuto: [],
      searchTypeAuto: null,

      isDialogVisible: false,
      isDialogVisibleEdad: false,
      nameOlds: [],
      newNameOld: {},
      modelConfig: {
        type: 'string',
        mask: 'YYYY-MM-DD',
      },
      itemsOperadores: [],
      allOperadores: [],
      searchOperador: null,

      itemsMarcas: [],
      allMarcas: [],
      searchMarcas: null,

      itemsRooms: [],
      allRooms: [],
      searchRooms: null,

      categories: [],
      itemsTipoDestino: [],
      allTipoDestino: [],
      searchTipoDestino: null,
      intereses: [],
      modelIntereses: [],
      servicios: [],
      modelServicios: [],
      galery: [],
      editGeoTag: true,
      address: {},
      edades: [],
      numeros: [],
      loadGalery: false,
      cumpleTags: false,
      galeriaHotels: [],
      isLoadingGalery: false,
      videos: [],
      videosHotels: [],
      isLoadingVideo: false,
      showIntereses: false,
      showServices: false,
      searchIntereses: '',
      searchServicios: '',
      selected: '',
      hotelIsHotetec: false,
      isDialogRooms: false,
      roomsOrigin: null,
    }
  },
  computed: {
    btnTitle() {
      return sessionStorage.getItem('hotels-id') === null ? this.$t('btn.send') : this.$t('btn.update')
    },
    ...mapState({
      addressHotel: state => state.app.addressHotel,
      onlyShow: state => state.app.onlyShow,
      loadImg: state => state.app.loadImg,
      videos_url: state => state.app.videos_url,

      // galeriaHotels: state => state.app.galeriaHotels,
    }),
    disabledInteres() {
      if (this.model.only_adult === true) {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.intereses.length; index++) {
          if (this.intereses[index].slug === 'apto-para-familias') {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.intereses[index].disabled = true
          } else {
            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
            this.intereses[index].disabled = false
          }
        }
      } else {
        // eslint-disable-next-line no-plusplus
        for (let index = 0; index < this.intereses.length; index++) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.intereses[index].disabled = false
        }
      }

      return this.intereses
    },
  },
  watch: {
    searchOperador(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterOperador(val.toLowerCase())
      } else {
        this.itemsOperadores = []
      }
    },
    searchMarcas(val) {
      if (val) {
        // eslint-disable-next-line no-unused-expressions
        this.filterMarcas(val.toLowerCase())
      }
    },
    searchTipoDestino(val) {
      // eslint-disable-next-line no-unused-expressions
      this.filterTipoDestino(val.toLowerCase())
    },
    searchTypeAuto(val) {
      if (val.length > 0) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterTypeAuto(val.toLowerCase())
      } else {
        this.itemsTypeAuto = []
      }
    },
    searchRooms(val) {
      if (val !== null && val !== undefined) {
        // eslint-disable-next-line no-unused-expressions
        val && this.filterRooms(val.toLowerCase())
      } else {
        this.itemsRooms = []
      }
    },
  },
  created() {
    this.getOperadores()
    this.getRooms()
    this.getCategorias()
    this.getTipoDestino()
    this.getIntereses()
    this.getServicios()
    this.getEdades()
    this.verifiItemIsHotetec()

    // eslint-disable-next-line no-plusplus
    for (let index = 1; index <= 10; index++) {
      this.numeros.push(index)
    }

    if (sessionStorage.getItem('hotels-show') !== null) {
      this.setOnlyShow(true)
    } else {
      this.setOnlyShow(false)
    }

    this.setImgLoading(true)

    /* setTimeout(() => {
      this.getItem()
      this.setImgLoading(false)
    }, 100) */
  },
  methods: {
    ...mapMutations([
      'adicionarImgHotels',
      'updateImgHotels',
      'adicionarContactHotel',
      'updateContactHotel',
      'adicionarUrlVideo',
      'updateUrlVideo',
      'updateAddressHotel',
      'updateGeoTag',
      'setOnlyShow',
      'setImgLoading',
      'adicionarVideoCars',
      'updateVideosCars',
      'resetAddressHotel',
      'resetGeoTag',
    ]),
    itemChange(e) {
      this.selected = e
      this.$nextTick(() => {
        this.searchRooms = ''
      })
    },
    clickNativeInteres($event) {
      this.selected = $event
      this.$nextTick(() => {
        this.searchIntereses = ''
      })
      if (this.intereses.filter(e => e.slug === 'todo-incluido').length > 0) {
        if ($event.includes(this.intereses.filter(e => e.slug === 'todo-incluido')[0].id)) {
          this.checkAllIncludeInteresAuto(true)
        } else {
          this.checkAllIncludeInteresAuto(false)
        }
      } else {
        this.checkAllIncludeInteresAuto(false)
      }

      if (this.intereses.filter(e => e.slug === 'solo-adultos').length > 0) {
        if ($event.includes(this.intereses.filter(e => e.slug === 'solo-adultos')[0].id)) {
          this.checkOnlyAdultInteresAuto(true)
        } else {
          this.checkOnlyAdultInteresAuto(false)
        }
      } else {
        this.checkOnlyAdultInteresAuto(false)
      }
    },
    clickNativeServicios($event) {
      this.selected = $event
      this.$nextTick(() => {
        this.searchServicios = ''
      })
      if (this.servicios.filter(e => e.slug === 'todo-incluido').length > 0) {
        if ($event.includes(this.servicios.filter(e => e.slug === 'todo-incluido')[0].id)) {
          this.checkAllIncludeServAuto(true)
        } else {
          this.checkAllIncludeServAuto(false)
        }
      } else {
        this.checkAllIncludeServAuto(false)
      }

      if (this.servicios.filter(e => e.slug === 'solo-adultos').length > 0) {
        if ($event.includes(this.servicios.filter(e => e.slug === 'solo-adultos')[0].id)) {
          this.checkOnlyAdultServiciosAuto(true)
        } else {
          this.checkOnlyAdultServiciosAuto(false)
        }
      } else {
        this.checkOnlyAdultServiciosAuto(false)
      }
    },
    allInclude() {
      let idTIInteres = 0
      if (this.intereses.filter(e => e.slug === 'todo-incluido').length > 0) {
        idTIInteres = this.intereses.filter(e => e.slug === 'todo-incluido')[0].id
      }

      let idTIServicios = 0
      if (this.servicios.filter(e => e.slug === 'todo-incluido').length > 0) {
        idTIServicios = this.servicios.filter(e => e.slug === 'todo-incluido')[0].id
      }

      // AGREGARLO A LOS INTERESES O SERVICIOS
      if (this.model.all_include) {
        if (!this.modelIntereses.includes(idTIInteres)) {
          this.modelIntereses.push(idTIInteres)
        }

        if (!this.modelServicios.includes(idTIServicios)) {
          this.modelServicios.push(idTIServicios)
        }
      } else {
        if (this.modelIntereses.includes(idTIInteres)) {
          const pos = this.modelIntereses.indexOf(idTIInteres)
          if (pos >= 0) {
            this.modelIntereses.splice(pos, 1)
          }
        }

        if (this.modelServicios.includes(idTIServicios)) {
          const pos = this.modelServicios.indexOf(idTIServicios)
          if (pos >= 0) {
            this.modelServicios.splice(pos, 1)
          }
        }
      }
    },
    checkAllInclude($event) {
      let idTIServicios = 0
      if (this.servicios.filter(e => e.slug === 'todo-incluido').length > 0) {
        idTIServicios = this.servicios.filter(e => e.slug === 'todo-incluido')[0].id
      }
      if ($event.target.control.checked) {
        this.model.all_include = true
        if (!this.modelServicios.includes(idTIServicios)) {
          this.modelServicios.push(idTIServicios)
        }
      } else {
        this.model.all_include = false
        const pos = this.modelServicios.indexOf(idTIServicios)
        if (pos >= 0) {
          this.modelServicios.splice(pos, 1)
        }
      }
    },
    checkAllIncludeInteresAuto($esta) {
      let idTIServicios = 0
      if (this.servicios.filter(e => e.slug === 'todo-incluido').length > 0) {
        idTIServicios = this.servicios.filter(e => e.slug === 'todo-incluido')[0].id
      }
      if ($esta) {
        this.model.all_include = true
        if (!this.modelServicios.includes(idTIServicios)) {
          this.modelServicios.push(idTIServicios)
        }
      } else {
        this.model.all_include = false
        const pos = this.modelServicios.indexOf(idTIServicios)
        if (pos >= 0) {
          this.modelServicios.splice(pos, 1)
        }
      }
    },
    checkAllIncludeServ($event) {
      let idTIInteres = 0
      if (this.intereses.filter(e => e.slug === 'todo-incluido').length > 0) {
        idTIInteres = this.intereses.filter(e => e.slug === 'todo-incluido')[0].id
      }
      if ($event.target.control.checked) {
        this.model.all_include = true
        if (!this.modelIntereses.includes(idTIInteres)) {
          this.modelIntereses.push(idTIInteres)
        }
      } else {
        this.model.all_include = false
        const pos = this.modelIntereses.indexOf(idTIInteres)
        if (pos >= 0) {
          this.modelIntereses.splice(pos, 1)
        }
      }
    },
    checkAllIncludeServAuto($esta) {
      let idTIInteres = 0
      if (this.intereses.filter(e => e.slug === 'todo-incluido').length > 0) {
        idTIInteres = this.intereses.filter(e => e.slug === 'todo-incluido')[0].id
      }
      if ($esta) {
        this.model.all_include = true
        if (!this.modelIntereses.includes(idTIInteres)) {
          this.modelIntereses.push(idTIInteres)
        }
      } else {
        this.model.all_include = false
        const pos = this.modelIntereses.indexOf(idTIInteres)
        if (pos >= 0) {
          this.modelIntereses.splice(pos, 1)
        }
      }
    },
    changeSugerido() {
      if (!this.model.sugerido) {
        this.model.number_sugerido = null
      }
      this.changeCumpleTags()
    },
    filterOperador(v) {
      this.itemsOperadores = []
      if (v === '') {
        this.itemsOperadores = []
      } else {
        this.itemsOperadores = this.allOperadores.filter(e => e.name.toLowerCase())
      }
    },
    filterMarcas() {
      this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())

      /* this.itemsMarcas = []
      if (v === '') {
        this.itemsMarcas = []
      } else {
        this.itemsMarcas = this.allMarcas.filter(e => e.name.toLowerCase())
      } */
    },
    filterTipoDestino() {
      this.itemsTipoDestino = this.allTipoDestino.filter(e => e.name.toLowerCase())
    },
    filterRooms(v) {
      this.itemsRooms = []
      if (v === '') {
        this.itemsRooms = []
      } else {
        this.itemsRooms = this.allRooms.filter(e => e.name.toLowerCase())
      }
    },
    changePublic() {
      if (!this.model.publicado) {
        this.model.sugerido = false
      }
    },

    getItem() {
      this.$store.state.app.loadEscalas = true
      if (sessionStorage.getItem('hotels-id') !== null) {
        this.zoom = 20
        const id = sessionStorage.getItem('hotels-id')
        this.axios
          .get(`hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            this.model = res.data.data.data
            this.edad_min = this.model.edad_min
            this.nameOlds = this.model.old_names !== null && this.model.old_names !== 'null' ? this.model.old_names : []
            this.modelIntereses = this.model.intereses_id !== null ? this.model.intereses_id : []
            this.modelServicios = this.model.servicios_id !== null ? this.model.servicios_id : []

            if (this.model.cant_departament && this.model.cant_departament !== 'null') {
              this.model.cant_bars = this.model.cant_departament.cant_bars
              this.model.cant_restaurant = this.model.cant_departament.cant_restaurant
              this.model.cant_rooms = this.model.cant_departament.cant_rooms
              this.model.cant_pools = this.model.cant_departament.cant_pools
              this.model.cant_coffee = this.model.cant_departament.cant_coffee
            }

            // GUARDAR LAS ROOMS ORIGIN
            this.roomsOrigin = JSON.stringify(this.model.rooms_id)

            // const mm = this.marcas.filter(a => a.id === this.model.marca_id)[0]
            // this.itemsMarcas.push(mm)
            // this.itemsModelos.push(mm.modelos.filter(a => a.id === this.model.modelo_id)[0])

            if (this.model.operador_id) {
              const ooo = this.allOperadores.filter(a => a.id === this.model.operador_id)
              if (ooo.length > 0) {
                this.itemsOperadores.push(ooo[0])
                if (this.itemsOperadores.length > 0) {
                  if (this.itemsOperadores[0].marcas.length > 0) {
                    this.allMarcas = this.itemsOperadores[0].marcas

                    if (this.allMarcas.length > 0) {
                      if (this.model.marca_asociada_id) {
                        if (this.allMarcas.filter(a => a.id === this.model.marca_asociada_id).length > 0) {
                          this.itemsMarcas.push(this.allMarcas.filter(a => a.id === this.model.marca_asociada_id)[0])
                          this.searchMarcas = this.itemsMarcas[0].name
                        }
                      } else {
                        this.itemsMarcas = this.allMarcas
                      }
                    }
                  }
                }
              }
            }

            /* if (this.model.galery) {
              if (this.model.galery.length > 0) {
                this.updateImgHotels(this.model.galery)
              } else {
                this.updateImgHotels([])
              }
            } else {
              this.updateImgHotels([])
            } */
            if (this.model.galery) {
              if (this.model.galery.length > 0) {
                this.galeriaHotels = this.model.galery
              } else {
                this.galeriaHotels = []
              }
            } else {
              this.galeriaHotels = []
            }

            if (this.model.videos !== null) {
              if (this.model.videos.length > 0) {
                this.videosHotels = this.model.videos
              } else {
                this.videosHotels = []
              }
            } else {
              this.videosHotels = []
            }

            if (this.model.videos_url) {
              if (this.model.videos_url.length > 0) {
                this.updateUrlVideo(this.model.videos_url)
              } else {
                this.updateUrlVideo([])
              }
            } else {
              this.updateUrlVideo([])
            }

            if (this.model.contactos) {
              if (this.model.contactos.length > 0) {
                this.updateContactHotel(this.model.contactos)
              } else {
                this.updateContactHotel([])
              }
            } else {
              this.updateContactHotel([])
            }

            if (this.model.direccion) {
              this.updateAddressHotel(this.model.direccion)
            }

            this.updateGeoTag({ longitud: this.model.longitud, latitud: this.model.latitud })
            this.$store.state.app.geotag.longitud = this.model.longitud
            this.$store.state.app.geotag.latitud = this.model.latitud

            setTimeout(() => {
              this.editGeoTag = false
            }, 100)
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.changeCumpleTags()
            this.isLoading = false
          })
      } else {
        this.updateImgHotels([])
        this.updateVideosCars([])
        this.updateContactHotel([])
        this.updateUrlVideo([])
        this.resetAddressHotel()
        this.resetGeoTag()
        setTimeout(() => {
          this.editGeoTag = false
        }, 100)
        this.isLoading = false
      }
    },
    verifiItemIsHotetec() {
      if (sessionStorage.getItem('hotels-id') !== null) {
        this.axios
          .post(
            'hotels/verify-contrate-hotetec',
            { id: sessionStorage.getItem('hotels-id') },
            {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            },
          )
          .then(res => {
            this.hotelIsHotetec = res.data.busy
          })
      }
    },
    resetGaleryItem() {
      if (sessionStorage.getItem('hotels-id') !== null) {
        this.isLoadingGalery = true
        const id = sessionStorage.getItem('hotels-id')
        this.axios
          .get(`hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.data.data.galery) {
              if (res.data.data.data.galery.length > 0) {
                this.galeriaHotels = res.data.data.data.galery
              } else {
                this.galeriaHotels = []
              }
            } else {
              this.galeriaHotels = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.isLoadingGalery = false
          })
      }
    },
    resetVideoItem() {
      if (sessionStorage.getItem('hotels-id') !== null) {
        this.isLoadingVideo = true
        const id = sessionStorage.getItem('hotels-id')
        this.axios
          .get(`hotels/${id}`, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(res => {
            if (res.data.data.data.videos) {
              if (res.data.data.data.videos.length > 0) {
                this.videosHotels = res.data.data.data.videos
              } else {
                this.videosHotels = []
              }
            } else {
              this.videosHotels = []
            }
          })
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.isLoadingVideo = false
          })
      }
    },

    getOperadores() {
      this.axios
        .get(`nom_operador_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allOperadores = res.data.data
        })
        .finally(() => {
          this.getItem()
          this.setImgLoading(false)
        })
    },
    setOperadores() {
      this.allMarcas = []
      if (this.allOperadores.filter(e => e.id === this.model.operador_id)[0].marcas.length > 0) {
        this.allMarcas = this.allOperadores.filter(e => e.id === this.model.operador_id)[0].marcas
        this.itemsMarcas = this.allMarcas
      }

      this.changeCumpleTags()
    },
    getRooms() {
      this.axios
        .get(`nom_habitacion_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allRooms = res.data.data
        })
    },
    getCategorias() {
      this.axios
        .get(`nom_categorias_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.categories = res.data.data
        })
    },
    getTipoDestino() {
      this.axios
        .get(`nom_tipo_destino_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.allTipoDestino = res.data.data
          this.itemsTipoDestino = res.data.data
        })
    },
    getIntereses() {
      this.axios
        .get(`nom_intereses_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.intereses = res.data.data
        })
    },
    getServicios() {
      this.axios
        .get(`nom_servicios_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.servicios = res.data.data
        })
    },
    getEdades() {
      this.axios
        .get(`nom_edad_hotels?per_page=1000&perfil_slug=${sessionStorage.getItem('perfil')}`, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(res => {
          this.edades = res.data.data
        })
    },
    onlyAdult() {
      let idSAInteres = 0
      if (this.intereses.filter(e => e.slug === 'solo-adultos').length > 0) {
        idSAInteres = this.intereses.filter(e => e.slug === 'solo-adultos')[0].id
      }

      // APTO PARA FAMILIAS
      let idAptoParaFamilias = 0
      if (this.intereses.filter(e => e.slug === 'apto-para-familias').length > 0) {
        idAptoParaFamilias = this.intereses.filter(e => e.slug === 'apto-para-familias')[0].id
      }

      let idSAServicios = 0
      if (this.servicios.filter(e => e.slug === 'solo-adultos').length > 0) {
        idSAServicios = this.servicios.filter(e => e.slug === 'solo-adultos')[0].id
      }

      // AGREGARLO A LOS INTERESES O SERVICIOS
      if (this.model.only_adult) {
        this.isDialogVisibleEdad = true

        if (!this.modelIntereses.includes(idSAInteres)) {
          this.modelIntereses.push(idSAInteres)
        }

        if (!this.modelServicios.includes(idSAServicios)) {
          this.modelServicios.push(idSAServicios)
        }

        // APTO PARA FAMILIAS
        if (this.modelIntereses.includes(idAptoParaFamilias)) {
          const pos = this.modelIntereses.indexOf(idAptoParaFamilias)
          if (pos >= 0) {
            this.modelIntereses.splice(pos, 1)
          }
        }
      } else {
        this.model.edad_min_id = null

        if (this.modelIntereses.includes(idSAInteres)) {
          const pos = this.modelIntereses.indexOf(idSAInteres)
          if (pos >= 0) {
            this.modelIntereses.splice(pos, 1)
          }
        }

        if (this.modelServicios.includes(idSAServicios)) {
          const pos = this.modelServicios.indexOf(idSAServicios)
          if (pos >= 0) {
            this.modelServicios.splice(pos, 1)
          }
        }
      }

      this.changeCumpleTags()
    },
    changeEdad() {
      this.isDialogVisibleEdad = false
      if (!this.model.edad_min_id) {
        this.model.only_adult = false

        let idSAInteres = 0
        if (this.intereses.filter(e => e.slug === 'solo-adultos').length > 0) {
          idSAInteres = this.intereses.filter(e => e.slug === 'solo-adultos')[0].id
        }

        let idSAServicios = 0
        if (this.servicios.filter(e => e.slug === 'solo-adultos').length > 0) {
          idSAServicios = this.servicios.filter(e => e.slug === 'solo-adultos')[0].id
        }
        if (this.modelIntereses.includes(idSAInteres)) {
          const pos = this.modelIntereses.indexOf(idSAInteres)
          if (pos >= 0) {
            this.modelIntereses.splice(pos, 1)
          }
        }

        if (this.modelServicios.includes(idSAServicios)) {
          const pos = this.modelServicios.indexOf(idSAServicios)
          if (pos >= 0) {
            this.modelServicios.splice(pos, 1)
          }
        }
      } else {
        // eslint-disable-next-line prefer-destructuring
        this.edad_min = this.edades.filter(e => e.id === this.model.edad_min_id)[0]
      }
    },
    changeModalEdad(id) {
      // eslint-disable-next-line prefer-destructuring
      this.edad_min = this.edades.filter(e => e.id === id)[0]
      this.changeCumpleTags()
    },
    checkOnlyAdultInteres($event) {
      let idSAServicios = 0
      if (this.servicios.filter(e => e.slug === 'solo-adultos').length > 0) {
        idSAServicios = this.servicios.filter(e => e.slug === 'solo-adultos')[0].id
      }
      if ($event.target.control.checked) {
        this.model.only_adult = true
        if (!this.modelServicios.includes(idSAServicios)) {
          this.modelServicios.push(idSAServicios)
        }
      } else {
        this.model.only_adult = false
        const pos = this.modelServicios.indexOf(idSAServicios)
        if (pos >= 0) {
          this.modelServicios.splice(pos, 1)
        }
      }
    },
    checkOnlyAdultInteresAuto($esta) {
      let idSAServicios = 0
      if (this.servicios.filter(e => e.slug === 'solo-adultos').length > 0) {
        idSAServicios = this.servicios.filter(e => e.slug === 'solo-adultos')[0].id
      }
      if ($esta) {
        this.model.only_adult = true
        if (!this.modelServicios.includes(idSAServicios)) {
          this.modelServicios.push(idSAServicios)
        }
      } else {
        this.model.only_adult = false
        const pos = this.modelServicios.indexOf(idSAServicios)
        if (pos >= 0) {
          this.modelServicios.splice(pos, 1)
        }
      }
    },
    checkOnlyAdultServicios($event) {
      let idSAInteres = 0
      if (this.intereses.filter(e => e.slug === 'solo-adultos').length > 0) {
        idSAInteres = this.intereses.filter(e => e.slug === 'solo-adultos')[0].id
      }
      if ($event.target.control.checked) {
        this.model.only_adult = true
        if (!this.modelIntereses.includes(idSAInteres)) {
          this.modelIntereses.push(idSAInteres)
        }
      } else {
        this.model.only_adult = false
        const pos = this.modelIntereses.indexOf(idSAInteres)
        if (pos >= 0) {
          this.modelIntereses.splice(pos, 1)
        }
      }
    },
    checkOnlyAdultServiciosAuto($esta) {
      let idSAInteres = 0
      if (this.intereses.filter(e => e.slug === 'solo-adultos').length > 0) {
        idSAInteres = this.intereses.filter(e => e.slug === 'solo-adultos')[0].id
      }
      if ($esta) {
        this.model.only_adult = true
        if (!this.modelIntereses.includes(idSAInteres)) {
          this.modelIntereses.push(idSAInteres)
        }
      } else {
        this.model.only_adult = false
        const pos = this.modelIntereses.indexOf(idSAInteres)
        if (pos >= 0) {
          this.modelIntereses.splice(pos, 1)
        }
      }
    },
    changeTipoDestino() {
      if (this.model.tipo_destino_id) {
        const typeDest = this.itemsTipoDestino.filter(e => e.id === this.model.tipo_destino_id)[0].slug

        // PARA LOS TIPO DESTINO CIUDAD
        if (typeDest === 'ciudad') {
          // DESTINOS URBANOS
          let idDestinosUrbanos = 0
          if (this.intereses.filter(e => e.slug === 'destinos-urbanos').length > 0) {
            idDestinosUrbanos = this.intereses.filter(e => e.slug === 'destinos-urbanos')[0].id
          }
          if (!this.modelIntereses.includes(idDestinosUrbanos) && idDestinosUrbanos > 0) {
            this.modelIntereses.push(idDestinosUrbanos)
          }

          // CAYOS
          let idCayos = 0
          if (this.intereses.filter(e => e.slug === 'cayos').length > 0) {
            idCayos = this.intereses.filter(e => e.slug === 'cayos')[0].id
          }
          if (idCayos > 0) {
            const pos = this.modelIntereses.indexOf(idCayos)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // NATURALEZA
          let idNaturaleza = 0
          if (this.intereses.filter(e => e.slug === 'naturaleza').length > 0) {
            idNaturaleza = this.intereses.filter(e => e.slug === 'naturaleza')[0].id
          }
          if (idNaturaleza > 0) {
            const pos = this.modelIntereses.indexOf(idNaturaleza)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // SOL Y PLAYA
          let idSolYPlaya = 0
          if (this.intereses.filter(e => e.slug === 'sol-y-playa').length > 0) {
            idSolYPlaya = this.intereses.filter(e => e.slug === 'sol-y-playa')[0].id
          }
          if (idSolYPlaya > 0) {
            const pos = this.modelIntereses.indexOf(idSolYPlaya)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }
        }

        // PARA LOS TIPO DESTINO NATURALEZA
        if (typeDest === 'naturaleza') {
          // NATURALEZA
          let idNaturaleza = 0
          if (this.intereses.filter(e => e.slug === 'naturaleza').length > 0) {
            idNaturaleza = this.intereses.filter(e => e.slug === 'naturaleza')[0].id
          }
          if (!this.modelIntereses.includes(idNaturaleza) && idNaturaleza > 0) {
            this.modelIntereses.push(idNaturaleza)
          }

          // CAYOS
          let idCayos = 0
          if (this.intereses.filter(e => e.slug === 'cayos').length > 0) {
            idCayos = this.intereses.filter(e => e.slug === 'cayos')[0].id
          }
          if (idCayos > 0) {
            const pos = this.modelIntereses.indexOf(idCayos)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // DESTINOS URBANOS
          let idDestinosUrbanos = 0
          if (this.intereses.filter(e => e.slug === 'destinos-urbanos').length > 0) {
            idDestinosUrbanos = this.intereses.filter(e => e.slug === 'destinos-urbanos')[0].id
          }
          if (idDestinosUrbanos > 0) {
            const pos = this.modelIntereses.indexOf(idDestinosUrbanos)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // SOL Y PLAYA
          let idSolYPlaya = 0
          if (this.intereses.filter(e => e.slug === 'sol-y-playa').length > 0) {
            idSolYPlaya = this.intereses.filter(e => e.slug === 'sol-y-playa')[0].id
          }
          if (idSolYPlaya > 0) {
            const pos = this.modelIntereses.indexOf(idSolYPlaya)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }
        }

        // PARA LOS TIPO DESTINO SOL Y PLAYA
        if (typeDest === 'sol-y-playa') {
          // SOL Y PLAYA
          let idSolYPlaya = 0
          if (this.intereses.filter(e => e.slug === 'sol-y-playa').length > 0) {
            idSolYPlaya = this.intereses.filter(e => e.slug === 'sol-y-playa')[0].id
          }
          if (!this.modelIntereses.includes(idSolYPlaya) && idSolYPlaya > 0) {
            this.modelIntereses.push(idSolYPlaya)
          }

          // CAYOS
          let idCayos = 0
          if (this.intereses.filter(e => e.slug === 'cayos').length > 0) {
            idCayos = this.intereses.filter(e => e.slug === 'cayos')[0].id
          }
          if (idCayos > 0) {
            const pos = this.modelIntereses.indexOf(idCayos)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // DESTINOS URBANOS
          let idDestinosUrbanos = 0
          if (this.intereses.filter(e => e.slug === 'destinos-urbanos').length > 0) {
            idDestinosUrbanos = this.intereses.filter(e => e.slug === 'destinos-urbanos')[0].id
          }
          if (idDestinosUrbanos > 0) {
            const pos = this.modelIntereses.indexOf(idDestinosUrbanos)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // NATURALEZA
          let idNaturaleza = 0
          if (this.intereses.filter(e => e.slug === 'naturaleza').length > 0) {
            idNaturaleza = this.intereses.filter(e => e.slug === 'naturaleza')[0].id
          }
          if (idNaturaleza > 0) {
            const pos = this.modelIntereses.indexOf(idNaturaleza)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }
        }

        // PARA LOS TIPO DESTINO CAYOS
        if (typeDest === 'cayos') {
          // SOL Y PLAYA
          let idSolYPlaya = 0
          if (this.intereses.filter(e => e.slug === 'sol-y-playa').length > 0) {
            idSolYPlaya = this.intereses.filter(e => e.slug === 'sol-y-playa')[0].id
          }
          if (!this.modelIntereses.includes(idSolYPlaya) && idSolYPlaya > 0) {
            this.modelIntereses.push(idSolYPlaya)
          }

          // CAYOS
          let idCayos = 0
          if (this.intereses.filter(e => e.slug === 'cayos').length > 0) {
            idCayos = this.intereses.filter(e => e.slug === 'cayos')[0].id
          }
          if (!this.modelIntereses.includes(idCayos) && idCayos > 0) {
            this.modelIntereses.push(idCayos)
          }

          // DESTINOS URBANOS
          let idDestinosUrbanos = 0
          if (this.intereses.filter(e => e.slug === 'destinos-urbanos').length > 0) {
            idDestinosUrbanos = this.intereses.filter(e => e.slug === 'destinos-urbanos')[0].id
          }
          if (idDestinosUrbanos > 0) {
            const pos = this.modelIntereses.indexOf(idDestinosUrbanos)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }

          // NATURALEZA
          let idNaturaleza = 0
          if (this.intereses.filter(e => e.slug === 'naturaleza').length > 0) {
            idNaturaleza = this.intereses.filter(e => e.slug === 'naturaleza')[0].id
          }
          if (idNaturaleza > 0) {
            const pos = this.modelIntereses.indexOf(idNaturaleza)
            if (pos >= 0) {
              this.modelIntereses.splice(pos, 1)
            }
          }
        }
      }
    },

    save() {
      if (this.model.name && this.model.operador_id && this.model.categoria_id && this.model.tipo_destino_id) {
        this.loading = true
        const cants = {
          cant_bars: this.model.cant_bars ? parseInt(this.model.cant_bars, 10) : 0,
          cant_restaurant: this.model.cant_restaurant ? parseInt(this.model.cant_restaurant, 10) : 0,
          cant_rooms: this.model.cant_rooms ? parseInt(this.model.cant_rooms, 10) : 0,
          cant_pools: this.model.cant_pools ? parseInt(this.model.cant_pools, 10) : 0,
          cant_coffee: this.model.cant_coffee ? parseInt(this.model.cant_coffee, 10) : 0,
        }
        const json = {
          name: this.model.name,
          checkIn: this.model.checkIn,
          checkOut: this.model.checkOut,
          old_names: this.nameOlds,
          operador_id: this.model.operador_id,
          marca_asociada_id: this.model.marca_asociada_id,
          categoria_id: this.model.categoria_id,
          tipo_destino_id: this.model.tipo_destino_id,
          only_adult: this.model.only_adult,
          all_include: this.model.all_include,
          edad_min_id: this.model.edad_min_id,
          rooms_id: this.model.rooms_id,
          intereses_id: this.modelIntereses,
          servicios_id: this.modelServicios,
          publicado: this.model.publicado,
          sugerido: this.model.sugerido,
          number_sugerido: this.model.number_sugerido,
          descripcion: this.model.descripcion,
          longitud: this.$store.state.app.geotag.longitud,
          latitud: this.$store.state.app.geotag.latitud,
          direccion: this.$store.state.app.addressHotel,
          contactos: this.$store.state.app.contactsHotel,

          // galery: this.$store.state.app.galeriaHotels,
          // videos: this.$store.state.app.videos,
          videos_url: this.videos_url,

          perfil_slug: sessionStorage.getItem('perfil'),

          cant_departament: cants,
        }

        if (sessionStorage.getItem('hotels-id') === null) {
          this.axios
            .post('hotels', json, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              // console.log(response)
              if (response.data.success === false) {
                if (response.data.data.status === 401) {
                  // this.$toast.error(this.$t('msg.noAutorice'))
                } else if (response.data.data.status === 402) {
                  this.$toast.error(this.$t('msg.existProduct', { name: this.model.name }))
                }
              } else {
                sessionStorage.removeItem('hotels-id')
                if (sessionStorage.getItem('contrato-hotels-show')) {
                  this.$router.push({ name: 'hotels-contrate-update' })
                } else {
                  this.$router.push({ name: 'hotels-list' })
                }
              }
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => (this.loading = false))
        } else {
          // VERIFICAR SI SE CAMBIO ALGUNA HABITACION
          // eslint-disable-next-line no-lonely-if
          if (this.hotelIsHotetec) {
            if (JSON.stringify(this.model.rooms_id) !== this.roomsOrigin) {
              this.loading = false
              this.isDialogRooms = true
            } else {
              this.excecuteJson(json)
            }
          } else {
            this.excecuteJson(json)
          }
        }
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    excecuteJson(json) {
      this.axios
        .put(`hotels/${this.model.id}`, json, {
          headers: {
            Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
          },
        })
        .then(response => {
          if (response.data.success === false) {
            // this.$toast.error(this.$t('msg.noAutorice'))
          } else {
            sessionStorage.removeItem('hotels-id')
            if (sessionStorage.getItem('contrato-hotels-show')) {
              this.$router.push({ name: 'hotels-contrate-update' })
            } else {
              this.$router.push({ name: 'hotels-list' })
            }
          }
        })
        .catch(error => console.log(error))
        // eslint-disable-next-line no-return-assign
        .finally(() => (this.loading = false))
    },
    confirmRoomsHotetec() {
      this.loading = true
      const cants = {
        cant_bars: this.model.cant_bars ? parseInt(this.model.cant_bars, 10) : 0,
        cant_restaurant: this.model.cant_restaurant ? parseInt(this.model.cant_restaurant, 10) : 0,
        cant_rooms: this.model.cant_rooms ? parseInt(this.model.cant_rooms, 10) : 0,
        cant_pools: this.model.cant_pools ? parseInt(this.model.cant_pools, 10) : 0,
        cant_coffee: this.model.cant_coffee ? parseInt(this.model.cant_coffee, 10) : 0,
      }
      const json = {
        name: this.model.name,
        checkIn: this.model.checkIn,
        checkOut: this.model.checkOut,
        old_names: this.nameOlds,
        operador_id: this.model.operador_id,
        marca_asociada_id: this.model.marca_asociada_id,
        categoria_id: this.model.categoria_id,
        tipo_destino_id: this.model.tipo_destino_id,
        only_adult: this.model.only_adult,
        all_include: this.model.all_include,
        edad_min_id: this.model.edad_min_id,
        rooms_id: this.model.rooms_id,
        intereses_id: this.modelIntereses,
        servicios_id: this.modelServicios,
        publicado: this.model.publicado,
        sugerido: this.model.sugerido,
        number_sugerido: this.model.number_sugerido,
        descripcion: this.model.descripcion,
        longitud: this.$store.state.app.geotag.longitud,
        latitud: this.$store.state.app.geotag.latitud,
        direccion: this.$store.state.app.addressHotel,
        contactos: this.$store.state.app.contactsHotel,

        // galery: this.$store.state.app.galeriaHotels,
        // videos: this.$store.state.app.videos,
        videos_url: this.videos_url,

        perfil_slug: sessionStorage.getItem('perfil'),

        cant_departament: cants,
      }
      this.excecuteJson(json)
    },
    addImg() {
      if (this.model.name && this.model.operador_id && this.model.categoria_id && this.model.tipo_destino_id) {
        this.loadGalery = true
        const cants = {
          cant_bars: this.model.cant_bars ? parseInt(this.model.cant_bars, 10) : 0,
          cant_restaurant: this.model.cant_restaurant ? parseInt(this.model.cant_restaurant, 10) : 0,
          cant_rooms: this.model.cant_rooms ? parseInt(this.model.cant_rooms, 10) : 0,
          cant_pools: this.model.cant_pools ? parseInt(this.model.cant_pools, 10) : 0,
          cant_coffee: this.model.cant_coffee ? parseInt(this.model.cant_coffee, 10) : 0,
        }
        const json = {
          name: this.model.name,
          checkIn: this.model.checkIn,
          checkOut: this.model.checkOut,
          old_names: this.nameOlds,
          operador_id: this.model.operador_id,
          marca_asociada_id: this.model.marca_asociada_id,
          categoria_id: this.model.categoria_id,
          tipo_destino_id: this.model.tipo_destino_id,
          only_adult: this.model.only_adult,
          edad_min_id: this.model.edad_min_id,
          intereses_id: this.modelIntereses,
          servicios_id: this.modelServicios,
          publicado: this.model.publicado,
          sugerido: this.model.sugerido,
          number_sugerido: this.model.number_sugerido,
          descripcion: this.model.descripcion,
          longitud: this.$store.state.app.geotag.longitud,
          latitud: this.$store.state.app.geotag.latitud,
          direccion: this.$store.state.app.addressHotel,
          contactos: this.$store.state.app.contactsHotel,

          perfil_slug: sessionStorage.getItem('perfil'),

          cant_departament: cants,
        }

        this.axios
          .post('hotels', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          .then(response => {
            // eslint-disable-next-line no-empty
            if (response.data.success === false) {
            } else {
              const { id } = response.data.data.data
              sessionStorage.setItem('hotels-id', id)
              this.model.id = id
            }
          })
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => (this.loadGalery = false))
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },

    setGalery() {
      if (this.galery.length > 0) {
        if (this.galery.length <= 20) {
          this.loadGalery = true
          const formData = new FormData()
          this.galery.forEach(element => {
            formData.append('images[]', element)
          })
          this.axios
            .post('galery', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              if (response.data.data.imgsNotSize) {
                if (response.data.data.imgsNotSize.length > 0) {
                  response.data.data.imgsNotSize.forEach(element => {
                    this.$toast.error(element)
                  })
                  this.$toast.error(this.$t('msg.msgImgsNotSize', { dimensions: '1080x720' }))
                }
              }

              if (response.data.data.data.length > 0) {
                const imgs = response.data.data.data
                const { resource } = response.data.data
                imgs.forEach(element => {
                  this.galeriaHotels.push({
                    tag: null,
                    principal: false,
                    resource,
                    image: element,
                  })
                })
              }

              // this.adicionarImgHotels({ galery: imgs, resource })
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.updateGalery())
        } else {
          this.$toast.error(this.$t('lbl.execImgUpload', { item: 20 }))
          this.galery = []
          this.loadGalery = false
        }
      }
    },
    updateGalery() {
      if (this.model.id && this.galeriaHotels.length > 0) {
        const json = {
          product_id: this.model.id,
          product: 'hotel',
          galery: this.galeriaHotels,

          // galery: this.$store.state.app.galeriaHotels,
        }

        this.axios
          .post('galery/galery-update', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {})
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.galery = []
            this.loadGalery = false
            this.changeCumpleTags()
          })
      } else {
        this.galery = []
        this.loadGalery = false
      }
    },

    setVideos() {
      if (this.videos.length > 0) {
        if (this.videos.length <= 5) {
          this.loadGalery = true
          const formData = new FormData()
          this.videos.forEach(element => {
            formData.append('videos[]', element)
          })
          const jsonCar = { product: 'hotels', product_id: sessionStorage.getItem('hotels-id') }
          formData.append('data', JSON.stringify(jsonCar))
          this.axios
            .post('videos', formData, {
              headers: {
                Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
              },
            })
            .then(response => {
              const videos = response.data.data.data
              const { resource } = response.data.data
              videos.forEach(element => {
                this.videosHotels.push({
                  procesado: false,
                  resource,
                  url: element,
                })
              })

              // this.adicionarVideoCars({ videos, resource })
            })
            .catch(error => console.log(error))
            // eslint-disable-next-line no-return-assign
            .finally(() => this.updateVideos())
        } else {
          this.$toast.error(this.$t('lbl.execImgUpload', { item: 5 }))
          this.videos = []
          this.loadGalery = false
        }
      }
    },
    updateVideos() {
      if (this.model.id) {
        const json = {
          product_id: this.model.id,
          product: 'hotel',
          videos: this.videosHotels,
        }

        this.axios
          .post('videos/update', json, {
            headers: {
              Authorization: `Bearer ${sessionStorage.getItem('sanctum_auth')}`,
            },
          })
          // eslint-disable-next-line no-unused-vars
          .then(response => {})
          .catch(error => console.log(error))
          // eslint-disable-next-line no-return-assign
          .finally(() => {
            this.videos = []
            this.loadGalery = false
          })
      }
    },

    showOldsName() {
      this.isDialogVisible = true
    },
    saveNameOld() {
      if (this.newNameOld.name && this.newNameOld.date) {
        this.nameOlds.push(this.newNameOld)
        this.newNameOld = {}

        this.nameOlds.sort()
      } else {
        this.$toast.error(this.$t('msg.datasRequiere'))
      }
    },
    deleteNameOld(index) {
      this.nameOlds.splice(this.nameOlds.indexOf(index), 1)
    },

    changeCumpleTags() {
      if (
        !this.model.name
        || !this.model.operador_id
        || !this.model.marca_asociada_id
        || !this.model.categoria_id
        || !this.model.tipo_destino_id
        || this.modelIntereses.length === 0
        || this.modelServicios.length === 0
        || !this.model.checkIn
        || !this.model.checkOut
        || this.model.cant_rooms === undefined
        || this.model.cant_rooms === null
        || this.model.cant_rooms === ''
        || this.model.cant_bars === undefined
        || this.model.cant_bars === null
        || this.model.cant_bars === ''
        || this.model.cant_pools === undefined
        || this.model.cant_pools === null
        || this.model.cant_pools === ''
        || this.model.cant_restaurant === undefined
        || this.model.cant_restaurant === null
        || this.model.cant_restaurant === ''
        || !this.model.descripcion
        || this.model.descripcion === '<p></p>'
        || (this.model.only_adult && !this.edad_min)
        || (this.model.sugerido && !this.model.number_sugerido)
        || !this.addressHotel.country
        || !this.addressHotel.state
        || !this.addressHotel.city
        || !this.addressHotel.direccion
        || !this.$store.state.app.geotag.longitud
        || !this.$store.state.app.geotag.latitud
        || this.galeriaHotels.length === 0
      ) {
        this.cumpleTags = false
        this.model.publicado = false
      } else {
        this.cumpleTags = true
      }

      let tieneP = false
      this.galeriaHotels.forEach(element => {
        if (!element.tag) {
          this.cumpleTags = false
          this.model.publicado = false
        }

        if (element.principal) {
          tieneP = true
        }
      })

      if (!tieneP) {
        this.cumpleTags = false
        this.model.publicado = false
      }
    },
  },
}
</script>
<style>
/**PARA OCULTAR LA FECHA DE HOY EN EL COMPONENTE DE HORA */
.vc-date-time .vc-date .vc-weekday,
.vc-date-time .vc-date .vc-month,
.vc-date-time .vc-date .vc-day,
.vc-date-time .vc-date .vc-year {
  display: none;
}
</style>
